import axios from "axios";

import http from "@/api/http.js";
import {PAGE_SIZE} from "@/utils/constants.js";

export default class Api {
    // AUTHORIZATION
    async login(form) {
        const {data} = await http.post("/auth/login", form);

        return data;
    }

    async refresh(refreshToken) {
        const {data} = await http.post("/auth/refresh", {refreshToken});

        return data;
    }

    // REGISTRATION
    async registration(form) {
        const {data} = await http.post("/reg", form);

        return data;
    }

    async verifyByCode(params) {
        const {data} = await http.post("/reg/verify", params);

        return data;
    }

    async emailValidation(email) {
        const {data} = await http.post("/reg/valid/email", {email});

        return data;
    }

    async accountValidate(account) {
        const {data} = await http.post("/reg/valid/account", {account});

        return data;
    }

    // DASHBOARD
    async dashboard(params) {
        const {data} = await http.post("/dashboard", params);

        return data.data;
    }

    async incomes(page, period, tagIds) {
        const params = {
            page,
            size: PAGE_SIZE,
            start: '',
            end: '',
            tagIds: []
        }
        if (tagIds != null && tagIds.length > 0) {
            params['tagIds'] = tagIds
        }
        if (period && period.length === 2) {
            params['start'] = period[0]
            params['end'] = period[1]
        }
        const {data} = await http.post("/dashboard/income", params);

        return data.data;
    }

    async incomesDays(size) {
        const params = {
            page: 0,
            size: size,
            start: '',
            end: '',
            tagIds: []
        }
        const {data} = await http.post("/dashboard/income", params);

        return data.data;
    }

    async download(params) {
        const {data} = await http.post("/download/income", params, { responseType: 'blob' });
        return data;
    }

    async tags() {
        const {data} = await http.get("/dashboard/tags");

        return data.data;
    }

    // MINERS
    async workers() {
        const {data} = await http.get("/miners");

        return data.data;
    }

    async stat(id, params) {
        const {data} = await http.post("/miners/stat", {
            id,
            ...params,
        });

        return data.data;
    }

    async nominal(params) {
        const {data} = await http.post("/miners/nominal", params);

        return data.data;
    }

    async getNominal(params) {
        const {data} = await http.post("/miners/getnominal", params);

        return data;
    }

    async deleteMiner(params) {
        const {data} = await http.post("/miners/delete", params);

        return data.data;
    }

    async saveMinerTags(params) {
        const {data} = await http.post("/miners/tag/save", params);

        return data.data;
    }

    // USERS
    async getUserTags() {
        const {data} = await http.get("/users/tags");

        return data.data;
    }

    async addUserTag(params) {
        const {data} = await http.post("/users/tag/add", params);

        return data.data;
    }

    async deleteUserTag(params) {
        const {data} = await http.post("/users/tag/delete", params);

        return data.data;
    }

    async user() {
        const {data} = await http.get("/users/current");

        return data.data;
    }

    async stratum() {
        const {data} = await http.get("/users/stratum");

        return data.data;
    }

    async resetPassword(email) {
        const {data} = await http.post("/users/resetPassword", {
            email,
        });

        return data;
    }

    async changePassword(params) {
        const {data} = await http.post("/users/changePassword", params);

        return data;
    }

    async watcherLinks() {
        const {data} = await http.get("/users/watchers");

        return data.data;
    }

    async addwatcher(params) {
        const {data} = await http.post("/users/addwatcher", params);

        return data.data;
    }

    async deletewatcher(params) {
        const {data} = await http.post("/users/deletewatcher", params);

        return data.data;
    }

    // BALANCE
    async balance() {
        const {data} = await http.get("/balance");

        return data.data;
    }

    // RATING
    async rating(period) {
        const data = await axios.get("https://poools.top/api/rank/pools/top/by-period", {
            headers: {
                Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlIjpbeyJhdXRob3JpdHkiOiJST0xFX1ZJUCJ9XSwiaWQiOjMsInN1YiI6InVzZXJ2aXAiLCJpYXQiOjE3MzM0NjQ0NzYsImV4cCI6MTc2NTAwMDQ3Nn0.C6grS89CWeEj9uQqFyZD2fo5A6PKnkjLr7O5K8esDLU"},
            params: {
                period,
                unit: "th",
            },
        });

        return data.data;
    }

    // WALLET
    async wallet() {
        const {data} = await http.get("/wallet");

        return data.data;
    }

    async network(asset) {
        const {data} = await http.get(`/wallet/network/${asset}`);

        return data.data;
    }

    async transactions(page, detailed) {
        const {data} = await http.get("/wallet/transactions", {
            params: {
                page,
                size: PAGE_SIZE,
                detailed: detailed
            },
        });

        return data.data;
    }

    async deposit(asset, network) {
        const {data} = await http.post("/wallet/deposit", {
            asset,
            network,
        });

        return data.data;
    }

    async withdraw(params) {
        const {data} = await http.post("/wallet/withdraw", params);

        return data.data;
    }

    async withdrawConfirm(params) {
        const {data} = await http.post("/wallet/withdraw/confirm", params);

        return data;
    }

    async convert(params) {
        const {data} = await http.post("/wallet/convert", params);

        return data.data;
    }

    async pair(params) {
        const {data} = await http.post("/wallet/pair", params);

        return data.data;
    }

    async settings() {
        const {data} = await http.get("/settings");

        return data.data;
    }

    async settingsChangePassword(params) {
        const {data} = await http.post("/settings/changePassword", params);

        return data;
    }

    async settingsResetPassword(params) {
        const {data} = await http.post("/settings/resetPassword", params);

        return data;
    }

    async settingsSaveNotification(params) {
        const {data} = await http.post("/settings/notification", params);

        return data;
    }

    async settingsWallet() {
        const {data} = await http.get("/settings/wallet");

        return data.data;
    }

    async settingsAutoPayout(params) {
        const {data} = await http.post("/settings/wallet", params);

        return data.data;
    }

    async settingsAddWallet(params) {
        const {data} = await http.post("/settings/wallet/add", params);

        return data;
    }

    //REFERRALS
    async agent() {
        const {data} = await http.get("/agent");

        return data.data;
    }

    async referrals() {
        const {data} = await http.get("/referral");

        return data.data;
    }

    async rank() {
        const {data} = await http.get("/agent/rank");

        return data.data;
    }

    async becomeAgent() {
        const {data} = await http.post("/agent/create", {});

        return data.data;
    }

    async addReferral(params) {
        const {data} = await http.post("/referral/add", params);

        return data.data;
    }

    async updateReferral(params) {
        const {data} = await http.put("/referral", params);

        return data.data;
    }

    async inviteReferral(params) {
        const {data} = await http.post("/referral/invite", params);

        return data.data;
    }

    //EXCHANGE RATE
    async exchangeRates() {
        const {data} = await http.get("/exrate");

        return data.data;
    }
}
