<template>
    <div class="tabs">
        <div>
			<button class="tabs-btn" @click="activeTab = 1" :class="{ active: activeTab === 1 }">RATING</button>
			<button class="tabs-btn" @click="activeTab = 2" :class="{ active: activeTab === 2 }">EXCHANGE</button>
		</div>
        <div class="tabs-body">
			<div class="tabs-body-item" v-if="activeTab === 1">
                <RatingContent
                    :tab="tab"
                    :rating="rating"
                    @tab="onTab"
                    @rating="onRating"
                />
			</div>
			<div class="tabs-body-item" v-if="activeTab === 2">
                <ExchangeRate :rates="rates"/>
			</div>
		</div>
    </div>
</template>

<script>
import RatingContent from "@/components/shared/rating/content/content.vue";
import ExchangeRate from "@/components/shared/exchange-rates/content.vue"

import {TAB_VALUES} from "@/components/shared/rating/pools/header.vue";

import {HASHRATE_UNIT} from "@/utils/constants"

import Api from "@/api/api";

const api = new Api();

const DEFAULT_FIXED_VALUE = 8;

export default {
    name: "Rating",
    components: {
        RatingContent,
        ExchangeRate
    },
    data() {
        return {
            activeTab: 1,
            tab: TAB_VALUES.step7days,
            rating: [],
            rates: []
        };
    },
    async created() {
        this.loadData()
        var helper = document.getElementsByClassName("b24-widget-button-wrapper")
        if (helper && helper[0] && helper[0].style) {
            helper[0].style.display = "none"
        }
    },
    methods: {
        async loadData() {
            const rating = await api.rating(this.tab)
            const data = await api.incomesDays(+this.tab)
            this.rates = await api.exchangeRates()
            this.onRating(rating, data.incomes);
        },

        onTab({value}) {
            this.tab = value;
            this.loadData()
        },

        onRating(rating, incomes) {
            if (!incomes.length) {
                let maxPayRate = rating[0].avr_pay_rate
                this.rating = rating.map((element) => {
                    const roundedRates = {};

                    for (const [date, rate] of Object.entries(element.rates)) {
                        if (element.pool_id != 'user') {
                            roundedRates[date] = Number(
                                (rate * 100000000).toFixed(DEFAULT_FIXED_VALUE)
                            );
                        }
                    }

                    return {
                        ...element,
                        rating: element.rating,
                        percent: (element.avr_pay_rate / maxPayRate * 100 ).toFixed(2),
                        avrRate: Number(
                            element.avr_pay_rate.toFixed(DEFAULT_FIXED_VALUE)
                        ),
                        rates: roundedRates,
                    };
                })
            } else {
                const userDaysRate = []
                const userRates = incomes.map(el => {
                    const userKoef = HASHRATE_UNIT[el.unit] / HASHRATE_UNIT['TH']
                    userDaysRate.push(el.day)
                    return {
                        date: el.day,
                        rate: (((+el.amount + +el.bonus) * 100000000) / + (el.hashrate * userKoef)).toFixed(DEFAULT_FIXED_VALUE)
                    }
                })
                const user_avr_pay_rate = userRates.reduce( function(sum, el) {
                    return sum + +el.rate;
                }, 0) / userRates.length

                const userRatesObj = {}
                userRates.forEach(r => {
                    userRatesObj[r.date] = +r.rate
                })

                const userRating = {
                    pool_id: 'user',
                    rating: 1,
                    percent: 100,
                    pool_name: "You",
                    pool_short_name: "YOU",
                    period: incomes.length,
                    rates: userRatesObj,
                    pool_favicon: null,
                    pool_url: null,
                    unit: "TH",
                    avr_pay_rate: user_avr_pay_rate,
                    is_user: true,
                }

                let maxPayRate = user_avr_pay_rate;

                rating.forEach(el => {
                    if (el.avr_pay_rate >= maxPayRate) {
                        maxPayRate = el.avr_pay_rate
                    }
                    if (user_avr_pay_rate < el.avr_pay_rate) {
                        userRating.rating = el.rating + 1
                    }
                })

                userRating.percent = +(user_avr_pay_rate / maxPayRate * 100).toFixed(2)

                rating.splice(userRating.rating - 1, 0, userRating)

                this.rating = rating.map((element) => {
                    const roundedRates = {};

                    for (const [date, rate] of Object.entries(element.rates)) {
                        if (element.pool_id != 'user') {
                            roundedRates[date] = Number(
                                (rate * 100000000).toFixed(DEFAULT_FIXED_VALUE)
                            );
                        }
                    }

                    return {
                        ...element,
                        rating: element.rating >= userRating.rating && element.pool_id != 'user' ? element.rating + 1 : element.rating,
                        percent: (element.avr_pay_rate / maxPayRate * 100 ).toFixed(2),
                        avrRate: Number(
                            element.avr_pay_rate.toFixed(DEFAULT_FIXED_VALUE)
                        ),
                        rates: element.pool_id != 'user' ? roundedRates : element.rates,
                    };
                });
            }
        },
    },
};
</script>

<style lang="scss">
.tabs-btn {
    margin-right: 5px;
	border: none;
	display: inline-block;
	background: #fff;
	padding: 20px 30px;
	text-align: center;
	cursor: pointer;
	margin-bottom: -3px;
	border-bottom: 3px solid #eee;
    border-radius: 16px 16px 0px 0px;
    font-size: 18px;
    line-height: 18px;
    color: #9e9fa8;
    text-transform: uppercase;
    font-weight: 700;
}
.tabs-btn.active {
	color: #5fa03a;
	border-bottom: 3px solid #5fa03a;
}
.tabs-body-item {
	padding: 3px 0;
}
</style>
