<template>
  <div class="exchange-rate exchange-rate-content">
    <div class="exchange-rate-control">
        <div class="rate-check" v-if="!isMobile">
            <UICheckbox :value="usdRub" :small="true" @click="usdRub = !usdRub"/>
            <span>USD/RUB</span>
        </div>
        <div class="rate-check"  v-if="!isMobile">
            <UICheckbox :value="btcUsd" :small="true" @click="btcUsd = !btcUsd"/>
            <span>BTC/USDT</span>
        </div>
        <div class="rate-check"  v-if="!isMobile">
            <UICheckbox :value="btcRub" :small="true" @click="btcRub = !btcRub"/>
            <span>BTC/RUB</span>
        </div>
        <UIDownload
            class="exchange-rate-control__download"
            :disabled="true"
            @onDownload="onDownload"
        />
    </div>
    <div class="exchange-rate-data">
        <div class="exchange-rate-table-header">
            <span class="exchange-rate-item-content"><b>Date</b></span>
            <span class="exchange-rate-item-content" v-if="!isMobile && usdRub"><b>USD/RUB</b></span>
            <span class="exchange-rate-item-content" v-if="!isMobile && btcUsd"><b>BTC/USDT</b></span>
            <span class="exchange-rate-item-content" v-if="btcRub"><b>BTC/RUB</b></span>
        </div>
        <div v-for="rate of rates" :key="rate.date" class="exchange-rate-table-row">
            <span class="exchange-rate-item-content">{{rate.date}}</span>
            <span class="exchange-rate-item-content" v-if="!isMobile && usdRub">{{rate.usdRub}}</span>
            <span class="exchange-rate-item-content" v-if="!isMobile && btcUsd">{{rate.btcUsdt}}</span>
            <span class="exchange-rate-item-content" v-if="btcRub">{{rate.btcRub}}</span>
        </div>
    </div>
  </div>
</template>

<script>
import UICheckbox from "@/components/ui/checkbox/checkbox.vue";
import UIDownload from "@/components/ui/download/download.vue";

export default {
  name: "ExchangeRate",
  components: {
    UICheckbox,
    UIDownload
  },
  props: {
      rates: {
          type: Array,
          default: () => [],
      },
  },
  data() {
    return {
        isMobile: false,
        usdRub: true,
        btcUsd: true,
        btcRub: true
    }
  },
  mounted() {
    if (window.innerWidth <= 768) {
        this.isMobile = true
    }
  }
}
</script>

<style lang="scss">
.exchange-rate {
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    position: relative;
}

.exchange-rate-content {
    background: $white;
    border-radius: 0px 16px 16px 16px;
    padding: 22px 20px 44px 20px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 34px;
}

.exchange-rate-control {
    display: flex;
}

.rate-check {
    display: flex;
    align-items: center;
    margin: 0px 10px;
}

.exchange-rate-control__download {
    margin-left: auto;
}

.exchange-rate-table-header {
    background: $bg-gray;
    border-radius: 10px;
    padding: 10px 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 25%);
}

.exchange-rate-table-row {
    padding: 6px 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 25%);
    border-radius: 10px;

    &:nth-child(2n) {
        background: $bg-gray-light;
    }
}

.exchange-rate-item-content {
    white-space: nowrap;
}

@media screen and (max-width: 768px) {
    .exchange-rate-control {
        display: flex;
        flex-direction: column;
    }

    .rate-check {
        display: flex;
        align-items: center;
        margin: 3px 0px;
    }

    .exchange-rate-table-header {
        background: $bg-gray;
        border-radius: 10px;
        padding: 10px 5px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .exchange-rate-table-row {
        padding: 6px 5px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-radius: 10px;

        &:nth-child(2n) {
            background: $bg-gray-light;
        }
    }
}
</style>